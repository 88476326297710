import React, { Component }  from 'react';
import '../helpers/suppress-install-prompt';
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { Container } from 'reactstrap';
import GraphQLClient from "../GraphQL/GraphQLClient";
import query from "../Content/ContentQuery";
import HpbaMarkdown from "../Content/HpbaMarkdown";

class Page extends Component {

    state={
        contemt: undefined
    };

    async componentDidMount(){
        const data = await GraphQLClient.request(query, { identifier: 'impressum'} );
        this.setState({content: data.content});
        console.log(data.content);
    }

    render(){
        return (
            <Layout
                page="cms-article"
                showMenu={true}
                showFooter={true}
                showExitup={false}
            >
                <SEO
                    title={this.state.content ? this.state.content.title : ""}
                >
                    <meta name="robots" content="noindex,nofollow" />
                </SEO>
                {this.state.content && (
                    <Container>
                        <header>
                            <h1>{this.state.content.title}</h1>
                        </header>
                        <section className="pt-0">
                            <div className="cms-content">
                                <HpbaMarkdown
                                    content={this.state.content.content.markdown}
                                />
                            </div>
                        </section>
                    </Container>
                )}
            </Layout> 
        );    
    }
}

export default Page;
