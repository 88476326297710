const query = `
    query Content($identifier: String!) {
        content(identifier: $identifier) {
            identifier
            title
            content {
                markdown
                html
            }
        }
    }
`;

export default query;
