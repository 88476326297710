import { GraphQLClient } from "graphql-request";
import 'url-search-params-polyfill';

const getAuthToken = () => {
    if (global && global.localStorage) {
        const urlParams = new URLSearchParams(global.location.search);

        if (urlParams.has('a')) {
            global.localStorage.setItem('token', urlParams.get('a'));
        }

        return global.localStorage.getItem('token');
    }

    return null;
};


const client = new GraphQLClient(process.env.API_URL, {
    mode: "cors",
    headers: {
        "X-Authentication": getAuthToken()
    }
});

export default client;
