import React, { Component } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import PropTypes from 'prop-types';
// import { ReactComponent as DownArrow } from 'assets/icons/chevron-down.svg';

class Faq extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false };
    }

    toggle() {
        this.setState(prevState => ({
            collapse: !prevState.collapse,
        }));
    }

    renderArrow() {
        if (this.state.collapse) {
            return (
                <span className="collapse-arrow open">
                    <span className="SVGInline">
                        {/* <DownArrow /> */}
                    </span>
                </span>
            );
        }

        return (
            <span className="collapse-arrow">
                <span className="SVGInline">
                    {/* <DownArrow /> */}
                </span>
            </span>
        );
    }

    render() {
        return (
            <div className="collapse-item">
                <h3>
                    <button type="button" onClick={this.toggle}>
                        {this.props.question}
                        {this.renderArrow()}
                    </button>
                </h3>
                <Collapse isOpen={this.state.collapse}>
                    <Card>
                        <CardBody>{this.props.answer}</CardBody>
                    </Card>
                </Collapse>
            </div>
        );
    }
}

Faq.propTypes = {
    question: PropTypes.string.isRequired,
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default Faq;
